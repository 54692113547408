.overall {
    height: 550px;
    position: absolute;
    border-radius: 10%;
}

.status {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 50%;
    background: rgba(204, 96, 68, 255);
    font-family: 'Plus Jakarta Sans';
    font-size: 150%;
}

.redText {
    color: rgba(240, 230, 215, 255);
}

.nonStatus {
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    font-family: 'Plus Jakarta Sans';
    font-size: auto;
    font-size: 20px;
    color: black;
    padding: 5%;
    background-color: white;
}
