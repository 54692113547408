.Rectangle {
    position: fixed;
    width: 450px;
    height: 329px;
    /* left: 1px;
    top: 493px; */
    bottom: 5px;
    /* top: 0; */
    /* height: 100%; */
    z-index: 4;
    /* Black/White */

    background: #fff;
    border-radius: 24px 24px 0px 0px;
    padding: 20px;
    margin: auto 1px;
    /* opacity:0.5; */
}

.logo {
    width: 30%;
    height: 14%;
    margin: 5% 35%;
}

.Please {
    margin: 5% 2%;
    font-family: 'Plus Jakarta Sans';
    color: black;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 17px;
}

.MobileNumber {
    font-family: 'Plus Jakarta Sans';
    color: black;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
    margin: 5% 2%;
}

.input {
    border-radius: 29px;
    width: 100%;
    /* margin:5%; */
    height: 50px;
    border: none;
    box-shadow: 2px 2px 10px 2px rgb(235, 229, 229);
    margin-bottom: 5%;
}

.submit {
    border-radius: 29px;
    width: 100%;
    /* margin:5% 5%; */
    background: #1d1d1d;
    height: 50px;
    border: none;
    color: white;
}
@media (max-width: 450px) {
    .Rectangle {
        width: 100vw;
        bottom: 0px;
    }
}
button {
    cursor: pointer;
}

.overhaul {
    position: fixed;
    background-color: rgb(0, 0, 0);
    top: 0;
    height: 100%;
    z-index: 4;
    opacity: 0.75;
}
