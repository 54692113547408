.nav-bar-component-on-select-css {
    color: rgb(255, 255, 255, 0.75) !important;
}

.ant-menu-submenu-selected .nav-bar-component-on-select-css {
    color: white !important;
    font-weight: bolder !important;
}

.nav-bar-component-on-select-css:hover {
    color: white !important;
}
